
export const navbar={
    ES:{
        inicio:"Inicio",
        empresa:"Empresa",
        comercio:"Comercio & Servicios",
        contacto:"Contacto"
    },
    EN:{
        inicio:"Home",
        empresa:"Enterprise",
        comercio:"Trade & Services",
        contacto:"Contact"
    }
}
export const servicio={
    ES:{
        obras:"Obras Náuticas:",
        obrasText:"En el dominio de obras Náuticas, Zelasprolac coordina proyectos de infraestructura portuaria, construcción y reparación de muelles, operaciones de dragado, construcción y rehabilitación de canales de navegación y de protecciones costero-fluviales.",
        obrasText2:"La empresa ha hecho una apuesta a la formación de colaboradores, gestión de alianzas internacionales y construcción de un equipo especializado, que permite la ejecucion de grandes obras con eficiencia.",
        exportacion:"Exportación de Alimentos: ",
        exportacionText:"Ofrecemos servicios de exportación de productos alimenticios tradicionales de diversas regiones de Europa con destino a todo el mundo, garantizando exclusividad y calidad en todos nuestros productos para brindar la mayor satisfacción a nuestros clientes."
    },
    EN:{
        obras:"Nautical Works:",
        obrasText:"In the Nautical Works domain, Zelasprolac coordinates port infrastructure projects, construction and reparation of docks, dredging operations, construction and rehabilitation of navigation channels and coastal-river protections.",
        obrasText2:"The company has made a commitment to the training of employees, management of international alliances and construction of a well specialized team, which allows the execution of large projects with high efficiency.",
        exportacion:"Food Exports: ",
        exportacionText:"We offer worldwide export services of traditional food products from various regions of Europe. Guaranteeing exclusivity and quality in all our products to provide the greatest satisfaction to our customers."
    }
}
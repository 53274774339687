export const contacto={
    ES:{
        contactanos:"Contactanos",
        visitanos:"Visítanos",
        llamanos:"Llámanos",
        escribenos:"Escríbenos",

    },
    EN:{
        contactanos:"CONTACT US",
        visitanos:"VISIT US",
        llamanos:"CALL US",
        escribenos:"E-MAIL US",
    }
}
export const slider={
    ES:{
        primera:"Dragando oportunidades, construyendo un futuro",
        segunda:"Eficiencia, rapidez y confiabilidad",
        tercera:"Exportamos productos de calidad con historia y tradición",

    },
    EN:{
        primera:"Dredging opportunities, building a future",
        segunda:"Efficiency, Swiftness and Reliability",
        tercera:"We export quality products with history and tradition",
    }
}
import React, { useState, useEffect, useRef} from 'react'
import { connect } from "react-redux";

//idioma
import {slider as SliderText} from '../../languageFunctions/slider'

//estilos
import './slider.css'

import Fade from 'react-reveal/Fade';


const Slider = ({idioma}) => {
    const [imgSelected, setImgSelected] = useState(1)
    const [sliderChange, setSliderChange] = useState(true)
    const keepVerifyingRef = useRef(true);
    useEffect(()=>{
        sliderFunction()
    },[])

    let imgSelec=1
    

    const sliderFunction=()=>{
 
            if(keepVerifyingRef.current){
                setTimeout(()=>{
                   
                    if(keepVerifyingRef.current){
                    imgSelec= imgSelec===3 ? 1 : imgSelec+1
                    setImgSelected(imgSelec)
                    sliderFunction()
                    }
    
                      },6000)

            }
    }

    return (
        <Fade duration={3500}>
        <div className="slider-container" id="inicio">

            <div className="slider-img" style={{top:0, left:imgSelected===1?"0%":"-100%"}}>
                <img src={process.env.PUBLIC_URL + '/img/taladro.jpg'} alt="Imagen de slider"></img>
            </div>

            <div className="slider-img" style={{top:0, left:imgSelected===1?"100%": imgSelected===2?"0%":"-100%"}}>
                <img src={process.env.PUBLIC_URL + '/img/barco.jpg'} alt="Imagen de slider"></img>
            </div>

            <div className="slider-img" style={{top:0, left:imgSelected===3?"0%":"100%"}}>
                <img src={process.env.PUBLIC_URL + '/img/envio.jpg'} alt="Imagen de slider"></img>
            </div>

            <div className="text-slider" style={{top:"10px", left:"4%", opacity:imgSelected===1?"1":"0", width:"28%"}}>
                <span>{SliderText(idioma).primera}</span>
            </div>

            <div className="text-slider" style={{top:"10px", right:"6%", opacity:imgSelected===2?"1":"0", width:"23%", textAlign:"right"}}>
                <span style={{color:"#fff"}} >{SliderText(idioma).segunda}</span>
            </div>

            <div className="text-slider" style={{top:"10px", left:"4%", opacity:imgSelected===3?"1":"0", width:"28%"}}>
                <span>{SliderText(idioma).tercera}</span>
            </div>

            <div className="sliderSelector" style={{fontFamily:"coolvetica"}}>
                
                <div className="sliderItem" onClick={()=>{setImgSelected(1); keepVerifyingRef.current = false;}}>
                    <span >1</span>
                    <div></div>
                </div>

                <div className="sliderItem" onClick={()=>{setImgSelected(2); keepVerifyingRef.current = false;}}>
                    <span>2</span>
                    <div></div>
                </div>

                <div className="sliderItem" onClick={()=>{setImgSelected(3); keepVerifyingRef.current = false;}}>
                    <span>3</span>
                    <div></div>
                </div>
            </div>
            
        </div>
        </Fade>
    )
}

const mapStateToProps = (state) => ({
    idioma: state.idioma,
    
  });

export default connect(mapStateToProps, null)(Slider)
